import { useDashboardTiles } from '../../hooks/useDashboardTiles';
import { DashboardTiles } from '../../DashboardTiles/DashboardTiles';
import React from 'react';
import { createStandardQueryClient } from '../../../../portal/utils/react-query/client';
import ReactDOM from 'react-dom/client';
import { ApplicationTypeProvider } from '../../../../ApplicationTypeContext';
import { QueryClientProvider } from '@tanstack/react-query';


declare global {
  interface Window {
    initTilesPage: (target: string) => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShowActionModal: (assetId: number|string, notes?: string, isMonitored?: boolean) => void;
  }
}
const queryClient = createStandardQueryClient();
export function initTilesPage(target: string) {
  const root = ReactDOM.createRoot(document.getElementById(target) as HTMLElement);
  root.render(
    <React.StrictMode>
      <ApplicationTypeProvider value="Portal">
        <QueryClientProvider client={queryClient}>
          <TilesPagePortalWrapper />
        </QueryClientProvider>
      </ApplicationTypeProvider>
    </React.StrictMode>
  );
}
window.initTilesPage = initTilesPage;
export function TilesPagePortalWrapper(_: {}) {
  const dashboardTiles = useDashboardTiles();
  return (<>
      <DashboardTiles {...dashboardTiles} showMenu={(s) => {
        window.ShowActionModal(s.uuid, s.orgLevel, s.isMonitored);
      }} />
  </>);
}
