import { isNullOrWhitespace } from '../common/util';
// import * as Sentry from '@sentry/react';
import type { Integration } from '@sentry/types/types/integration';

export async function configurePortalSentry() {
  const Sentry = await import('@sentry/react');
  if (isNullOrWhitespace(window.SentryDSN)) {
    return;
  }
  let integrations: Integration[] = [
    Sentry.replayIntegration({
      maskAllText: false, // show everything on the screen
      blockAllMedia: false, // show everything on the screen
      maskAllInputs: false // show everything on the screen
    })
  ];
  if (window.User?.Email) {
    Sentry.setUser({
      email: window.User.Email,
      username: window.User.Name
    });
    integrations = [
      ...integrations,
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'dark',
        buttonLabel: '',
        formTitle: 'Feedback',
        submitButtonLabel: 'Submit',
        showBranding: false,
        messagePlaceholder: ''
      })
    ];
  }
  Sentry.init({
    dsn: window.SentryDSN,
    sendDefaultPii: true,
    integrations: integrations,
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0
  });
}
