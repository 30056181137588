import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { type SubmissionInfo, GetSubmissionAndSchemaInfoByActivityId, type ExistingSubmissionAndSchemaInfo } from '../../form/api';
import { arrayToMap } from '../../../common/util';

type ExistingFormsContextData = {
  activityId: string;
  formSchemaIdValueMap: Map<number, SubmissionInfo> | undefined;
  requiredForms: Map<number, SubmissionInfo> | undefined;
  nonRequiredForms: Map<number, ExistingSubmissionAndSchemaInfo> | undefined;
  formSubmissionQuery: UseQueryResult<SubmissionInfo[], Error>;
  formSubmittedBySchemaId: Map<number, boolean | undefined> | undefined;
};

const FormDataContext = createContext<ExistingFormsContextData | null>(null);

export function useFormDataContext() {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useExistingFormsContext must be used within a ExistingFormsContextProvider');
  }
  return context;
}

export function useFormData() {
  const { formSchemaIdValueMap, requiredForms, nonRequiredForms, formSubmittedBySchemaId, formSubmissionQuery } = useFormDataContext();
  return { formSchemaIdValueMap, requiredForms, nonRequiredForms, formSubmittedBySchemaId, formSubmissionQuery };
}

export function FormDataProvider({ activityId, children }: { activityId: string } & PropsWithChildren) {
  const submissionAndSchemaInfoQuery = useQuery({
    queryKey: ['existingForms', activityId],
    queryFn: async () => {
      return await GetSubmissionAndSchemaInfoByActivityId(activityId);
    }
  });

  const submissionFormsBySchemaId = useMemo(
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data ?? [],
            (form) => form.formSchemaId,
            (form) => form
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const requiredFormsBySchemaId = useMemo(
    // showing required forms regardless of whether they have a submission
    // this allows the slam to appear by default
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => form.required) ?? [],
            (form) => form.formSchemaId,
            (form) => form
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const existingNonRequiredFormsBySchemaId = useMemo(
    // only showing non-required forms that have an existing submission
    // this makes it so all forms don't appear by default
    // the AddFormModal shows all forms that have not been submitted and that are not required
    () =>
      submissionAndSchemaInfoQuery.data
        ? (arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => !form.required && form.id !== null) ?? [],

            (form) => form.formSchemaId,
            (form) => form
          ) as Map<number, ExistingSubmissionAndSchemaInfo>)
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const formSubmittedBySchemaId = useMemo(
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => !form.data?.submit) ?? [],
            (form) => form.formSchemaId,
            (form) => form.data?.submit
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const contextValue = useMemo(
    () => ({
      activityId: activityId,
      formSchemaIdValueMap: submissionFormsBySchemaId,
      requiredForms: requiredFormsBySchemaId,
      nonRequiredForms: existingNonRequiredFormsBySchemaId,
      formSubmissionQuery: submissionAndSchemaInfoQuery,
      formSubmittedBySchemaId: formSubmittedBySchemaId
    }),
    [activityId, submissionFormsBySchemaId, submissionAndSchemaInfoQuery, formSubmittedBySchemaId, existingNonRequiredFormsBySchemaId, requiredFormsBySchemaId]
  );
  return <FormDataContext.Provider value={contextValue}>{children}</FormDataContext.Provider>;
}
