import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { isToday, isTomorrow, startOfDay } from 'date-fns';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import React, { useEffect, useRef, useState } from 'react';
import { type QueryObserverResult, useMutation } from '@tanstack/react-query';
import { useRmxServiceApi } from '../api';
import { TimeDropDown } from '../../utils/TimeDropDown/TimeDropDown';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import s from './RmxServiceTime.module.scss';
import { formatTime } from '../../../util/formatters';
import { ReactFormSelect } from '../../utils/ReactFormSelect/ReactFormSelect';
import type { Activity, MyTime } from '../Service.types';
import type { DataTableResponse } from '../../shared-compat/DataTable/hooks';
import { handleAxiosError } from '../../../common/util/http';
import { DateInput } from '../../utils/DateInput';
import { NonActivityTimeDateInputGuide } from '../../onboardings/NonActivityTimeDateInputGuide';
import type { TimeInfo } from './RmxServiceTime';

interface Props {
  activity?: Activity;
  enabled?: boolean;
  options: string[];
  refetchTimeTable?: () => Promise<QueryObserverResult<DataTableResponse<MyTime>, Error>>;
  refetchActivityTimeRows?: () => Promise<unknown>;
  timeTableData?: TimeInfo[];
  onSubmit?: () => void;
}

export function RmxServiceTimePicker({ activity, options, refetchActivityTimeRows, refetchTimeTable, onSubmit, timeTableData }: Props) {
  const api = useRmxServiceApi();

  const [startTime, setStartTime] = useState(startOfDay(new Date()));
  const [endTime, setEndTime] = useState(startOfDay(new Date()));
  const [entryDate, setEntryDate] = useState<Date>(activity?.startDate ? new Date(activity?.startDate) : new Date());

  const [totalTimeSpan, setTotalTimeSpan] = useState<string>('00:00');

  const [type, setType] = React.useState('Select');

  const hourDifference = useRef(endTime.getHours() - startTime.getHours());
  const minuteDifference = useRef(endTime.getMinutes() - startTime.getMinutes());
  const [totalTimeDate, setTotalTimeDate] = useState<Date>(new Date());
  const [reason, setReason] = useState('');
  const dateInputRef = useRef<HTMLInputElement>(null);

  function TypeSelect(selectedType: string, disabled?: boolean) {
    const typeOptions = options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));

    return (
      <ReactFormSelect defaultValue={selectedType} size={'sm'} disabled={disabled} onChange={(e) => setType(e.target.value)}>
        {typeOptions}
      </ReactFormSelect>
    );
  }

  useEffect(() => {
    hourDifference.current = endTime.getHours() - startTime.getHours();
    minuteDifference.current = endTime.getMinutes() - startTime.getMinutes();
    const start = startTime.getTime();
    const end = endTime.getTime();
    const difference = Math.max(0, end - start); // Ensure non-negative difference

    const hours = Math.floor(difference / 3600000);
    const minutes = Math.floor((difference % 3600000) / 60000);
    setTotalTimeSpan(formatTime(hours, minutes));

    const newTotalTimeDate = new Date();
    newTotalTimeDate.setHours(0, 0, 0, 0);
    newTotalTimeDate.setHours(hours, minutes);
    setTotalTimeDate(newTotalTimeDate);
  }, [startTime, endTime, type]); // Include setters in dependencies if they could change

  const { mutateAsync: postActivityTime, isPending: pendingPostActivityTime } = useMutation({
    mutationFn: async () => {
      try {
        await api.postActivityTime(startTime, endTime, type, totalTimeDate, activity?.id, reason, entryDate);
      } catch (e) {
        const message = handleAxiosError(e);
        alert(message);
      }

      refetchActivityTimeRows && activity && (await refetchActivityTimeRows());
      refetchTimeTable && (await refetchTimeTable());
    }
  });

  async function handleSubmit() {
    if (hourDifference.current === 0 && minuteDifference.current === 0) {
      alert('Please fill in both start time and end time.');
      return;
    } else if (startTime === endTime) {
      alert('Start time and end time cannot be the same.');
      return;
    } else if (startTime > endTime) {
      alert('Start time cannot be greater than end time.');
      return;
    }
    if (activity && !isToday(activity.startDate) && !isTomorrow(activity.startDate)) {
      alert('Cannot add time to an activity if current date does not match start date or the day after the start date.');
      return;
    }
    if (activity && isTomorrow(entryDate) && timeTableData?.length === 0) {
      alert('Add time to the original activity start date before adding time to the next day.');
      return;
    }

    if (type === 'Select') {
      alert('Please select a type.');
      return;
    }

    if (!activity && reason === '') {
      alert('Please enter a reason.');
      return;
    }
    await postActivityTime();
    setStartTime(startOfDay(new Date()));
    setEndTime(startOfDay(new Date()));
    refetchTimeTable && (await refetchTimeTable());
    onSubmit && onSubmit();
  }

  const timeTableColumns = [
    {
      header: 'Date',
      accessorKey: 'date',
      valueFormatter: () => {
        return (
          <div ref={dateInputRef} style={{ display: 'flex', justifyContent: 'center', minWidth: '100px' }}>
            {
              <DateInput
                className={s['date-input']}
                filterDate={
                  activity
                    ? (date) => {
                        const today = new Date(activity.startDate);
                        const tomorrow = new Date(activity.startDate);
                        tomorrow.setDate(today.getDate() + 1);
                        return date.toLocaleDateString() === today.toLocaleDateString() || date.toLocaleDateString() === tomorrow.toLocaleDateString();
                      }
                    : undefined
                }
                value={entryDate}
                onChange={function (date: Date | null): void {
                  if (date) {
                    setEntryDate(date);
                  }
                }}
              />
            }
          </div>
        );
      }
    },
    {
      header: 'Start Time',
      accessorKey: 'startTime',
      valueFormatter: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TimeDropDown
              defaultDate={startOfDay(entryDate)}
              disabled={false}
              onChange={setStartTime}
              increment={15}
              // entry date is set to activity start date if it exists, otherwise it is set to today
              // If the entry date is tomorrow, only allow 3 hours of time to be generated
              hoursToGenerate={activity && isTomorrow(entryDate) ? 3 : 24}
            />
          </div>
        );
      }
    },
    {
      header: 'End Time',
      accessorKey: 'endTime',
      valueFormatter: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TimeDropDown defaultDate={startOfDay(entryDate)} disabled={false} onChange={setEndTime} increment={15} hoursToGenerate={23.75} />
          </div>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      valueFormatter: () => {
        return <div style={{ display: 'flex', justifyContent: 'center' }}>{<InputGroup.Text className={s['text']}>{totalTimeSpan}</InputGroup.Text>}</div>;
      }
    },
    {
      header: 'Type',
      accessorKey: 'type',
      valueFormatter: () => {
        return <div style={{ display: 'flex', justifyContent: 'center', minWidth: '100px' }}>{TypeSelect(type)}</div>;
      }
    },
    {
      header: '',
      accessorKey: 'actions',
      valueFormatter: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {
              <LoadingButton
                size='sm'
                variant='secondary'
                loading={pendingPostActivityTime}
                onClick={async () => {
                  await handleSubmit();
                }}
                style={{ width: '100%' }}
                label='Submit'
              />
            }
          </div>
        );
      }
    }
  ] satisfies SimpleColumnDef[];

  !activity && timeTableColumns.pop(); // Remove the actions column if not adding time to an activity

  return (
    <Card>
      <Card.Body style={{ overflowY: 'auto' }}>
        <ReactTable tableClassName='time-table' includeHeaders={true} columns={timeTableColumns} singleRowFormTable={true} />
        {!activity && (
          <>
            <Row className={'mb-4'}>
              <Col>
                {/*Todo Add debounce saving capability*/}
                <InputGroup style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Form.Label> Reason </Form.Label>
                  <Form.Control
                    size={'lg'}
                    style={{ width: '100%' }}
                    as='textarea'
                    value={reason}
                    aria-label='With textarea'
                    placeholder={'Describe the reason for adding non-activity time'}
                    onChange={(event) => setReason(event.target.value)}
                  />
                  {/* Conditionally render the "Saving" message */}
                  {/*{status === 'pending' && <div style={{ color: 'white' }}>Saving...</div>}*/}
                  {/*{status === 'success' && <div style={{ color: 'green' }}>Saved</div>}*/}
                  {/*{status === 'error' && <div style={{ color: 'red' }}>Error saving scope. Please check your internet connection and type again.</div>}*/}
                </InputGroup>
              </Col>
            </Row>

            <Row className={'mb-3'}>
              <Col>
                <LoadingButton
                  style={{ width: '100%' }}
                  variant='secondary'
                  onClick={async () => {
                    await handleSubmit();
                  }}
                  label={'Submit'}
                  loading={pendingPostActivityTime}
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
      {/*TODO remove onboarding once techs are familiar with concept*/}
      {/*Placed this here to obtain dateInput ref*/}
      {!activity && <NonActivityTimeDateInputGuide dateInputRef={dateInputRef} />}
    </Card>
  );
}
