import { Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { type ReactElement, type ReactNode, useState } from 'react';
import { DateInput } from '../../utils/DateInput';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { ReactFormSelect } from 'src/portal/utils/ReactFormSelect/ReactFormSelect';
import { type DropdownOption } from 'src/portal/utils/SearchableDropdown/SearchableDropdown';
import { GroupedSectionedSelect } from '../../utils/GroupSectionedSelect/GroupedSectionedSelect';

type EditingButtonProps = {
  loading?: boolean;
  className?: string;
  onSave: () => void;
  onCancel: () => void;
  style?: React.CSSProperties;
};
type Option<T> = { value: T; label: string };

export function stringsToOptions(values: string[]): Option<string>[] {
  return values.map((value) => ({ value, label: value }));
}
type FieldRowTypes = 'textarea';
type FieldRowProps<T, TKey extends StringKeyOf<T>, TValue = unknown> = {
  editable?: boolean;
  value: TValue;
  onSave?: (name: TKey, value: T[TKey], extra?: T[TKey]) => Promise<void> | void;
  header: ReactElement | string;
  headerIcon?: ReactElement;
  name: TKey;
  display?: DisplayFieldFormatter<TValue>;
  extraContent?: ReactNode;
  input?: (value: TValue, onChange: React.Dispatch<TValue>) => ReactNode;
  options?: Option<TValue>[];
  groupedOptions?: { label: string; options: DropdownOption<number>[] }[];
  type?: FieldRowTypes;
  isLoading?: boolean;
  onEditClick?: () => void;
};

export function MakeFieldRow<T>() {
  return FieldRow as <TKey extends StringKeyOf<T> = StringKeyOf<T>, TValue = unknown>(props: FieldRowProps<T, TKey, TValue>) => ReactNode;
}

type StringKeyOf<T> = keyof T & string;

export function FieldRow<T, TKey extends StringKeyOf<T> = StringKeyOf<T>, TValue = unknown>({
  extraContent,
  editable,
  value,
  onSave,
  onEditClick,
  header,
  headerIcon,
  name,
  isLoading,
  display,
  input,
  options,
  groupedOptions,
  type
}: FieldRowProps<T, TKey, TValue>) {
  const [currentEditingValue, setCurrentEditingValue] = useState<TValue | undefined>(undefined);
  const handleEditClick = () => setCurrentEditingValue(value);
  const handleCancelClick = () => setCurrentEditingValue(undefined);

  const handleSaveClick = async () => {
    if (currentEditingValue === undefined) return;
    await onSave?.(name, currentEditingValue as T[TKey]);
    setCurrentEditingValue(undefined);
  };

  const editingRow = currentEditingValue !== undefined;

  const inputJsx = editingRow && input ? input(currentEditingValue!, setCurrentEditingValue) : undefined;
  return (
    <tr>
      <td>
        <div style={{ display: 'flex', gap: '5px' }}>
          {header}
          {headerIcon}
        </div>
      </td>
      <td style={{ paddingLeft: '5px' }}>
        {isLoading ? (
          <Spinner size={'sm'} />
        ) : editingRow ? (
          inputJsx ?? (
            <RowInput
              rowName={name}
              value={currentEditingValue}
              onChange={setCurrentEditingValue}
              options={options}
              groupedOptions={groupedOptions}
              type={type}
            />
          )
        ) : (
          <DisplayField rowName={name} value={value} formatter={display} />
        )}
      </td>
      {!isLoading && editingRow ? (
        <td style={{ verticalAlign: 'middle' }}>
          <EditingButtons onSave={handleSaveClick} onCancel={handleCancelClick} />
        </td>
      ) : (
        <td style={{ verticalAlign: 'middle' }}>{editable && <EditButton onClick={onEditClick ?? handleEditClick} />}</td>
      )}
      {extraContent}
    </tr>
  );
}

type DisplayFieldFormatter<TValue> = (value: TValue) => ReactNode;

function DisplayField<TV>({ value, formatter }: { value: TV; rowName: string; status?: string; formatter?: DisplayFieldFormatter<TV> }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>{formatter ? formatter(value) : value instanceof Date ? value.toDateString() : (value as any)}</div>
  );
}

export function EditingButtons({ loading, className, style, onCancel, onSave }: EditingButtonProps) {
  return (
    <div style={style ?? { display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', width: '115px' }}>
      <LoadingButton
        className={className}
        size={'sm'}
        variant={'secondary'}
        disabled={false}
        onClick={onSave}
        loading={loading ?? false}
        label={<FontAwesomeIcon icon={faCheck} style={{ color: '#21C01B' }} />}
      />
      <LoadingButton
        className={className}
        size={'sm'}
        variant={'secondary'}
        disabled={false}
        onClick={onCancel}
        loading={loading ?? false}
        label={<FontAwesomeIcon icon={faTimes} style={{ color: '#FF4646' }} />}
      />
    </div>
  );
}

export function EditButton({
  loading,
  onClick,
  style,
  className
}: {
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  loading?: boolean;
}) {
  return (
    <div style={style ?? { display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px', width: '115px' }}>
      <LoadingButton
        className={className}
        size={'sm'}
        variant={'secondary'}
        onClick={onClick}
        label={<FontAwesomeIcon icon={faPencilAlt} style={{ color: '#00CDE5' }} />}
        loading={loading ?? false}
      />
    </div>
  );
}

interface RowInputProps<TValue> {
  setData?: (value: TValue) => void;
  rowName: string;
  value: TValue;
  onChange: (value: TValue) => void;
  options?: Option<TValue>[];
  groupedOptions?: { label: string; options: DropdownOption<number>[] }[];
  type?: FieldRowTypes;
}

function RowInput<TValue>(props: RowInputProps<TValue>) {
  if (props.groupedOptions !== undefined) {
    const selectedAssetOption = props.groupedOptions.flatMap((g) => g.options).find((a) => a.value === props.value);
    return <GroupedSectionedSelect value={selectedAssetOption} options={props.groupedOptions} onChange={(value) => props.onChange(value.value as TValue)} />;
  }
  if (props.options !== undefined) {
    return (
      <ReactFormSelect
        value={props.value as string}
        style={{ fontSize: '13px', width: '100%' }}
        size={'sm'}
        onChange={(event) => {
          props.onChange(event.currentTarget.value as TValue);
        }}
      >
        {props.options.map((option) => (
          <option key={String(option.value)} value={option.value as string | number}>
            {option.label}
          </option>
        ))}
      </ReactFormSelect>
    );
  }
  if (props.type === 'textarea') {
    return (
      <Form.Control
        size='sm'
        as={'textarea'}
        value={props.value as string}
        rows={3}
        cols={450}
        onChange={(event) => {
          props.onChange(event.currentTarget.value as TValue);
        }}
        style={{ display: 'flex', alignContent: 'stretch' }}
      />
    );
  }
  switch (props.rowName) {
    default:
      if (typeof props.value === 'string') {
        return (
          <Form.Control
            size='sm'
            style={{ width: '100%' }}
            type='text'
            value={props.value}
            onChange={(event) => props.onChange(event.currentTarget.value as TValue)}
          />
        );
      }
      if (props.value instanceof Date) {
        return <DateInput value={props.value} onChange={(d) => props.onChange(d as TValue)} />;
      } else return <span>Not Supported</span>;
  }
}
