import { useQuery } from '@tanstack/react-query';
import { getAssetData } from '../../../../../../api/userDashboardApi';
import { seconds } from '../../../../../../common/util';

import { ReactQueryLayout } from '../../../../../../components/util/LoadableResourceLayout';
import { AssetDetailContent } from './AssetDetailContent';
import React, { useMemo } from 'react';
import { AssetSection } from '../../../InstallationDashboard/components/AssetSection/AssetSection';
import { useApplyAssetPath } from '../../../../DashboardRoutePaths';
import { AssetReportButton } from './AssetReportButton/AssetReportButton';
import { AlertTable } from '../../../InstallationDashboard/components/AlertTable/AlertTable';
import { convertAlertStatusSymbolToStatusIndicatorSymbol, StatusIndicator } from '../../../../../../portal/status-indicator/StatusIndicator/StatusIndicator';
import { useGetPreviousBreadCrumbLink } from '../../../../DashboardBreadCrumbs/DashboardBreadCrumbs';
import { PanelLayout } from '../../../../../../components/util/PanelLayout/PanelLayout';
import type { AssetOpenMenuModal } from '../../../../../../portal/menu-modal/MenuModal/portal-asset-context';


export function AssetDetailsWrapper({ showDiagnostics }: { showDiagnostics: boolean }) {
  const assetUuid = useMemo(() => new URLSearchParams(window.location.search).get('pid')!, []);
  const queryResult = useQuery({
    queryKey: ['asset-details-page', assetUuid],
    queryFn: async () => await getAssetData(assetUuid, true),
    refetchInterval: seconds(60)
  }), { data: assetData, refetch } = queryResult;

  const getPreviousBreadCrumbLink = useGetPreviousBreadCrumbLink();

  const applyAssetPath = useApplyAssetPath();
  return (
    <PanelLayout
      headerLeft={assetData && <StatusIndicator
        className={'ml-2 ms-2'}
        color={assetData.alertStatus.color}
        label={assetData.name}
        requestIndicatorNumber={0}
        symbol={convertAlertStatusSymbolToStatusIndicatorSymbol(assetData.alertStatus.symbol)}
        onClick={() => (window.OpenIndicatorMenuModal as AssetOpenMenuModal)(assetData.id, 'Asset', assetData.name, assetData.isMonitored)}
      />}
      breadCrumbs={assetData?.breadCrumbs}
      darker={true}
      backLink={getPreviousBreadCrumbLink(assetData?.breadCrumbs)}
      buttons={[]}>
      <ReactQueryLayout state={queryResult}>
        {() => (
          <>

            {assetData && <>
              <AlertTable alerts={assetData.alerts} type={'Asset'} />
              <AssetReportButton asset={assetData} />

                  <AssetDetailContent asset={assetData} refetch={refetch} showDiagnostics={showDiagnostics} />
                  {assetData!.modules.length > 0 &&
                    <AssetSection
                      getAssetLink={a => applyAssetPath(a)}
                      sectionLabel={`${assetData?.name} Modules`}
                      assets={assetData!.modules}
                      withBorder={true}
                      reload={refetch}
                    />}
                  {Object.keys(assetData!.embeddedAssets).map((key: string) =>
                    <AssetSection
                      getAssetLink={a => applyAssetPath(a)}
                      key={key}
                      sectionLabel={`Embedded ${key}`}
                      assets={assetData!.embeddedAssets[key]}
                      withBorder={true}
                      reload={refetch}
                    />)}

            </>}
          </>
        )}
      </ReactQueryLayout>
    </PanelLayout>
  );
}
