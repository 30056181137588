/* eslint-disable */
import { MenuConfig, openBypassModal } from './MenuModal';
import { ActionConfig } from '../ActionModal/ActionModal';
import { openServiceModal } from '../../rmx-service/RmxServiceComponent/RmxServiceComponentModal';
import { getLegacyPortalApi } from 'src/portal/legacy-portal-api/legacy-api';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';
import { getNotesFromJQElement, type PortalOrgLevel } from '../../utils/Legacy';

/**
 * This File contains  the company and site menu modals, action modals, and indicator modals
 * initialized in the CompanyTiles and the SiteTiles pages
 */
// @ts-ignore
window.initializeMenuModal = async () => {
  const legacyApi = getLegacyPortalApi();
  async function ViewAlertDetails(id: string, type: string): Promise<any> {
    try {
      $('#dialog-actions').hide();
      $('#dialog-actions').dialog('close');
    } catch (e) {}
    try {
      const oData = await legacyApi.getAlertDetailsAsync(id, type);
      // @ts-ignore
      $('#dialog-alert-details').html(oData.data);
      $('#dialog-alert-details').show();
      $(function () {
        $('#dialog-alert-details').dialog({
          autoOpen: true,
          dialogClass: 'no-close',
          resizable: false,
          height: 'auto',
          width: 400,
          modal: true,

          // @ts-ignore
          fluid: true, //new option
          open: function () {
            jQuery('.ui-widget-overlay').on('click', function () {
              jQuery('#dialog-alert-details').dialog('close');
            });
          },
          buttons: [
            {
              text: 'OK',
              class: 'btn btn-block btn-lg',
              click: function () {
                $(this).dialog('close');
              }
            }
          ],
          show: {
            effect: 'drop',
            duration: 500
          },
          hide: {
            effect: 'clip',
            duration: 500
          }
        });
      });
      $('.selector').dialog({
        closeOnEscape: false
      });
    } catch (err) {}
  }

  let orgId = '';
  let orgType = '';
  const modal_title = '';

  async function openCompanyOrSiteMenuModal(id: string, type: PortalOrgLevel, modalTitle: string, isMonitored: boolean) {
    const url = new URL(window.location.href);
    let menuConfig: MenuConfig<any> = { getLabel: () => modalTitle };
    let actionConfig: ActionConfig<any> = {};

    // these are required to be defined or else bypasses will not work

    if (type === 'Site') {
      // if we are at the site level

      // get the company id from any tile
      const companyId = window.PageParams.CompanyId;
      const siteId = id;
      menuConfig = {
        isMonitored,
        open: () => {
          window.location.href = `${window.origin}?p=InstallationPage&pid=${siteId}`;
        },
        alerts: () => {
          ViewAlertDetails(id, type);
        },
        flag: () => {},
        graphing: () => {
          window.location.href = `${window.origin}?p=ChartingPrefill&sId=${siteId}`;
        },
        reports: () => {
          window.location.href = `${window.origin}?p=SystemAssessment&cId=${companyId}&sId=${siteId}`;
        },
        getLabel: () => modalTitle,

        service: async () => {
          const rmxSiteId = await legacyApi.getSiteIntIdByGuid(siteId);
          openServiceModal({ siteId: rmxSiteId });
        },

        bypass: async () => {
          // const siteId = await getSiteIntIdByGuid(id);
          const siteName = await legacyApi.getCompanyOrSiteNameAsync(id, type);
          const rmxApi = getPortalServiceApi();
          const syncedUser = await rmxApi.syncedUser();
          openBypassModal({ id: id, siteName: siteName, type: 'Site', syncedUser: syncedUser });
        },
        edit: async () => {
          const elem = getNotesFromJQElement(id, type);
          const noteText = elem.text();
          const rmxId = await legacyApi.getIdByGuidAndType(id, type);

          // @ts-ignore
          window.OpenNotesModal(rmxId, type, noteText);
        }
      };
    } else if (type === 'Company') {
      // if we are at the company level
      menuConfig = {
        isMonitored,
        open: () => {
          window.location.href = `${window.origin}?p=SiteTiles&pid=${id}`;
        },

        // action: () => {
        // }, // empty action handler used to show the action button
        alerts: () => {
          ViewAlertDetails(id, type);
        },
        // history: () => {}, // coming soon
        graphing: () => {
          window.location.href = `${window.origin}?p=ChartingPrefill&cId=${id}`;
        },
        getLabel: () => modalTitle,
        // change: () => {},
        // snooze: () => {}, // coming soon
        edit: async () => {
          const elem = getNotesFromJQElement(id, type);
          const noteText = elem.text();

          const rmxId = await legacyApi.getIdByGuidAndType(id, type);
          window.OpenNotesModal(rmxId, type, noteText);
        } // coming soon
      };
    }

    if (!window.User.IsRmxServiceEnabled) {
      delete menuConfig.service;
      delete menuConfig.flag;
    }

    window.OpenMenuModal(modalTitle, menuConfig, actionConfig);
  }

  // @ts-ignore
  window.OpenIndicatorMenuModal = async function (id: string, type: string) {
    const url = new URL(window.location.href);
    const modalTitle = await legacyApi.getCompanyOrSiteNameAsync(id, type);

    // get the company id from any tile
    // const element = document.querySelector(`.colored-tile`);
    // .log(element)
    // const anchor = element!.querySelector('a.hoverDarken');
    // const href = anchor!.getAttribute('href');
    // const ids = href!.split('pid=')[1];

    const companyId = type === 'Site' ? window.PageParams.CompanyId : id;
    // these are required to be defined or else bypasses will not work
    orgId = id;
    orgType = type;
    const menuConfig: MenuConfig<any> = {
      // view_requests: () => {
      //   // view requests
      //   // @ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // // action: () => {}, // empty action handler used to show the action button
      // request: () => {
      //   // @ts-ignore
      //   window.openSubmitRequestDialogFor(id, type);
      // },
      alerts: () => (window.location.href = `${url.origin}?p=Alerts&c=${companyId}`),
      graphing: () => {
        window.location.href = `${window.origin}?p=ChartingPrefill&cId=${companyId}`;
      },
      getLabel: () => modalTitle
      // history: () =>{}
    };

    const actionConfig: ActionConfig<any> = {
      view_requests: () => {
        // @ts-ignore
        window.openViewRequestsDialog(id, type);
      },
      submit_requests: () => {
        // @ts-ignore
        window.openSubmitRequestDialogFor(id, type);
      }
      // snooze: () => {}, // coming soon
      // edit: () => {openNotesDialog(id, type)},
      // add_bypass: () => {}, // bypasses don't exist at the company level
      // clear_bypass: () => {}, // bypasses don't exist at the company level
    };

    window.OpenMenuModal(modalTitle, menuConfig, actionConfig);
  };

  //@ts-ignore
  window.ShowActionModal = async function (id: string, type: PortalOrgLevel, isMonitored: boolean) {
    const modal_title = await legacyApi.getCompanyOrSiteNameAsync(id as string, type);
    // new menu modal
    await openCompanyOrSiteMenuModal(id, type, modal_title, isMonitored);
  };

  function AddByPass() {
    window.performShowByPass(modal_title);
  }

  // @ts-ignore
  window.Manage = function () {
    // @ts-ignore
    if (window.isTabFormat && window.currentSelectedTab === 'tab_active_alerts') {
      //@ts-ignore
      window.ManageAlert();
    } else {
      ManageFromTiles();
    }
  };

  function ManageFromTiles() {
    window.performAddBypass(orgType === 'Site' ? '/Alerts/ManageSite' : '/Alerts/ManageCompany', orgType === 'Site' ? { SiteID: orgId } : { CompanyID: orgId });
  }
};
