import { GrowingTextArea } from '../GrowingTextArea';
import React, { useState } from 'react';
import DarkSelect from '../../shared-compat/utils/DarkSelect/DarkSelect';
import { GenericModal } from '../GenericModal/GenericModal';
import { type ModalState } from '../../hooks/useModal';
import { FormRow } from '../../rmx-service/Flags/RmxViewFlag';
import { LoadingButton } from '../widgets/LoadingButton/LoadingButton';
import { type OrgLevel } from '../../../api/userDashboardApi';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';
import { handleAxiosError } from '../../../common/util/http';
import { type BypassAction, getLegacyPortalApi } from '../../legacy-portal-api/legacy-api';

export interface BypassModalProps {
  id: number | string;
  siteName: string;
  assetModel?: string;
  assetSerial?: string;
  type: OrgLevel;
  syncedUser: boolean;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    openBypassModal: (f: BypassModalProps) => void;
  }
}

export function BypassModal({ state }: { state: ModalState<BypassModalProps> }) {
  const legacyApi = getLegacyPortalApi();
  const api = getPortalServiceApi();
  const [reason, setReason] = useState<string>('');
  const [action, setAction] = useState<BypassAction>('Ignore');

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <GenericModal label={'Bypass'} state={state} showClose={true}>
        <FormRow label={'Reason'} content={<GrowingTextArea readOnly={false} value={reason} setValue={setReason} />} />
        {state.param?.syncedUser && (
          <FormRow label={'Action'} content={<DarkSelect onChange={(e) => setAction(e)} options={['Ignore', 'Create Flag']} value={action} />} />
        )}
        <LoadingButton
          style={{ width: '100%' }}
          label={'Submit'}
          disabled={false}
          variant='secondary'
          onClick={async () => {
            try {
              setLoading(true);
              const requests: Promise<unknown>[] = [
                legacyApi.createBypass({ id: state.param!.id, otherReason: reason, bypassAction: action }, state.param!.type)
              ];
              if (action === 'Create Flag') {
                requests.push(
                  api.createFlag({
                    userGroup: 'Support',
                    reason:
                      state.param!.type === 'Asset'
                        ? `Site: ${state.param?.siteName};  Asset Model: ${state.param?.assetModel}; Asset Serial: ${state.param?.assetSerial}; Reason: ${reason}`
                        : `Site: ${state.param?.siteName}; Reason: ${reason}`
                  })
                );
              }
              await Promise.all(requests);
              state.close();
              window.location.reload();
            } catch (e) {
              alert(`Error Bypassing: ${handleAxiosError(e)}`);
            } finally {
              setLoading(false);
            }
          }}
          loading={loading}
        />
      </GenericModal>
    </>
  );
}
