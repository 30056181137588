import {type SystemProcessData} from '../pages/Dashboard/pages/InstallationDashboard/components/SystemProcessComponent/SystemProcessComponent';
import {api} from './index';
import {type Installation} from 'src/common/redux/entities/installation';
import {type SimpleUserViewAsset, type UserViewAsset} from 'src/common/redux/entities/installationEntities/assetRevision';
import {type SystemProcess} from 'src/common/redux/entities/installationEntities/spRevision';
import {type NamedEntity} from 'src/common/types/Entity';
import {getEnumValues, makeUrl} from 'src/common/util';
const completedFilter = {
  CompletedOnly: false
};
export const hiddenFilter = {
  ShowHidden: true
};

export type AlertColor = 'Red' | 'Orange' | 'Blue' | 'Gray';
export type AlertSymbol = 'TriangleAlert' | 'CircleAlert' | 'Info' | 'Check'| 'NoConnection' | 'Bypass' | 'NeverCalledIn' | 'Folder';
export const OrgLevels = ['Global', 'Dealer', 'Company', 'Site',  'Installation',  'SystemProcess', 'Asset'] as const;
export type OrgLevel = typeof OrgLevels[number];

export enum EOrgType {
  Asset = 'Asset',
  SystemProcess = 'SystemProcess',
  Installation = 'Installation',
  Site = 'Site',
  Company = 'Company',
  Dealer = 'Dealer',
  Global = 'Global'
}
export const OrgTypeValues = getEnumValues(EOrgType);
export type BreadCrumbDataType = `${EOrgType}`;

export interface AlertStatus extends AlertStatusInfo{
  id: number;
  isMonitored: boolean;
  uuid: string;
  name: string;
  nextName: string;
  nextId: string;
  nextType: OrgLevel;
  notes: string|null;
}

export interface AlertStatusInfo {
  sortOrder: number;
  color: AlertColor;
  symbol: AlertSymbol;
  orgLevel: OrgLevel;
}




export interface BreadCrumbData {
  label: string;
  id: string;
  intId: number;
  type: BreadCrumbDataType;
  disabled: boolean;
}

export interface StatusResponse {
  statuses: AlertStatus[];
  breadCrumbs: BreadCrumbData[];
}


export async function getStatuses(level: OrgLevel | null, id?: string | null, search?: string) {
  return (await api.get<StatusResponse>(`/Status/ByLevel`, {
    params: {
      level: level,
      parentUuid: id || null,
      search: search?.trim() !== '' ? search?.trim() ?? null : null
    }
  })).data;
}

export async function addBypass(id: number, level: OrgLevel) {
  return await api.post('/Bypass', {
    id: id,
    orgLevel: level
  });
}


export async function getInstallationData(uuid: string) {
  return (await api.get<InstallationData>(makeUrl(`/installation/uuid/:0/data`, uuid), {
    params: {...hiddenFilter}
  })).data;
}

export async function getInstallationDataBySiteUuid(uuid: string) {
  return (await api.get<InstallationData>(makeUrl(`/installation/site/uuid/:0/data`, uuid), {
    params: {...hiddenFilter}
  })).data;
}

export async function getSystemProcessData(uuid: string): Promise<SystemProcessData> {
  return (await api.get<SystemProcessData>(`/SystemProcess/uuid/${uuid}/data`, {
    params: {...completedFilter}
  })).data;
}

export interface UserDataPointResponse {
  name: string;
  value: string | null;
  commCode: number | null;
  commCodeName: string | null;
  found: boolean;
  stringValue: string | null;
  unit: string;
  hourMeterId: number | null;
}



export interface UserAssetResponse {
  isMonitored: boolean;
  components: { componentName: string; formattedDaysRemainingStatus: string; formattedLastChangeDate: string }[];
  componentLifeEnabled: boolean;
  id: number;
  name: string;
  assetGroup: NamedEntity;
  manufacturer: NamedEntity;
  dataPoints: UserDataPointResponse[];
  productionStatuses: UserDataPointResponse[];
  specs: UserDataPointResponse[];
  operationalStatuses: string[];
  modules: SimpleUserViewAsset[];
  embeddedAssets: { [assetGroupName: string]: SimpleUserViewAsset[] };
  breadCrumbs: BreadCrumbData[];
  coverageType: CoverageType | null;
  notes: string | null;
  siteName: string;
  companyName: string;
  alerts: DashboardAlertDto[];
  alertStatus: AlertStatusInfo;
}


type CoverageType = {
  id: number;
  name: string;
  code: string;
};

export async function getAssetData(
  assetUuid: string, portalMode: boolean = false): Promise<UserAssetResponse> {
  const url = makeUrl(`/asset/uuid/:0/data`, assetUuid);
  return (await api.get<UserAssetResponse>(url, {
    params: {...completedFilter, portalMode: portalMode}
  })).data;
}

export async function getAssetDataByRmxId(
  assetId: number, portalMode: boolean = false): Promise<UserAssetResponse> {
  const url = makeUrl(`asset/:0/data`, assetId.toString());
  return (await api.get<UserAssetResponse>(url, {
    params: {...completedFilter, portalMode: portalMode}
  })).data;
}


export type AssetCoverageType = {coverageTypes: CoverageType[]; currentCoverageTypeId: number|null; currentCoverageTypeName: string|null};
export async function getAssetCoverageTypes(assetId: number) {
  return (await api.get<AssetCoverageType>(`/Asset/${assetId}/CoverageType`)).data;
}
type UpsertCoverageType = {
  assetId: number;
  coverageTypeId: number|null;
  coverageTypeName: string;
};
export async function upsertCoverageType(req: UpsertCoverageType) {
  return await api.post(`/Asset/CoverageType`, req);
}
type UpdateRmxHours = {
  assetId: number;
  hours: number|null;
  hourMeterId: number;
};
export async function updateRmxHours(req: UpdateRmxHours) {
  return await api.post(`/Asset/UpdateRmxHours`, req);
}

export function getAssetImageUrl(assetId: number, imageHash: number) {
  return `${import.meta.env.VITE_HOST_URL}api/v1/image/asset/${assetId}?t=${imageHash}`;
}

export async function uploadAssetImage(imageData: FormData) {
  return await api.post('/image/asset', imageData, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}

export async function saveNote(type: OrgLevel, id: number, note: string) {
  return await api.post(`/Notes/SaveNote`, {note, target: type, id});
}


export interface InstallationData {
  unmonitoredAssets: { [assetGroupName: string]: UserViewAsset[] };
  siteName: string;
  siteUuid: string;
  installation: Installation|null;
  assets: { [assetGroupName: string]: UserViewAsset[] };
  systemProcesses: SystemProcess[];
  breadCrumbs: BreadCrumbData[];
  alerts: DashboardAlertDto[];
  alertStatus: AlertStatusInfo;
}


export type DashboardAlertDto = {
  definition: string;
};
