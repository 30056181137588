import {type SimpleUserViewAsset} from 'src/common/redux/entities/installationEntities/assetRevision';
import {type BreadCrumbData, EOrgType, type OrgLevel} from 'src/api/userDashboardApi';
import {getObjectKeys, isNullOrUndefined, lowercase, makeUrl} from 'src/common/util';
import {z} from 'zod';
import {type IconButtonProps} from 'src/components/util/Panel/Panel';
import {RoutePaths} from 'src/router/RoutePaths';
import {useLocation, useParams} from 'react-router-dom';
import { useApplicationType } from '../../ApplicationTypeContext';
import { getPortalUrlBasePathByType } from './DashboardBreadCrumbs/DashboardBreadCrumbs';

const Dashboard = '/dashboard' as const;
const CompanyDashboard = `${Dashboard}/${lowercase(EOrgType.Company)}/:name/:id` as const;
const SiteDashboard = `${Dashboard}/${lowercase(EOrgType.Site)}/:name/:id` as const;
const InstallationDashboard = `${Dashboard}/${lowercase(EOrgType.Installation)}/:name/:id` as const;
const SystemProcessDashboard = `${Dashboard}/${lowercase(EOrgType.SystemProcess)}/:name/:id`as const;
const AssetDetails = `${Dashboard}/${lowercase(EOrgType.Asset)}/:name/:id`as const;

export const DashboardPaths = {
  Dashboard,
  CompanyDashboard,
  SiteDashboard,
  InstallationDashboard,
  SystemProcessDashboard,
  AssetDetails
} as const;

export const IdBasedRouteParams = z.object({id: z.string()});
export type IdRouteParams = z.infer<typeof IdBasedRouteParams>;

export function useGetOrgLevelFromUrl() {
  const appType = useApplicationType();
  if(appType === 'Portal') {
    return getOrgLevelFromPortalUrl;
  }
  return getOrgLevelFromUrl;
}

export function getOrgLevelFromPortalUrl(url: string) : OrgLevel | null {
  const search = new URLSearchParams(window.location.search);
  const page = search.get('p');
  if(page === 'SiteTiles') {
    return 'Company';
  }
  return null;
}
export function getOrgLevelFromUrl(url: string): OrgLevel | null {
  if(url.indexOf('/dashboard/') === -1)
    return null;
  const secondHalf = url.split('/dashboard/')[1];
  if(secondHalf.indexOf('/') === -1)
    return secondHalf as OrgLevel;
  return secondHalf.split('/')[0] as OrgLevel;
}

export function useRouteId(paramName?: string)  {
  const appType = useApplicationType();
  if(appType === 'Portal') {
    const search = new URLSearchParams(window.location.search);
    return search.get(paramName ?? 'pid')!;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams<any>();
  if(paramName)
    return params[paramName] as string;
  else
    return params.id! as string;
}

export function useRouteParam<T>(paramName: string) {
  const params = useParams<any>();
  return params[paramName] as T;
}


export function useCurrentUrl() {
  const appType = useApplicationType();
  if(appType === 'Portal') {
    return window.location.href;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  return location.pathname;
}
export function parseUrlParams<T extends Record<string, string|null|undefined>>(p: T) {
  const result: T = {} as T;
  const keys = getObjectKeys(p);
  for (const key of keys) {
    const value = p[key];
    result[key] = !isNullOrUndefined(value) ? decodeURIComponent(value) as T[typeof key] : value;
  }
  return result;
}

export function getBaseLinkByOrgLevel(l: OrgLevel) {
  switch (l) {
    case 'Asset': return DashboardPaths.AssetDetails;
    case 'SystemProcess': return DashboardPaths.SystemProcessDashboard;
    case 'Installation': return DashboardPaths.InstallationDashboard;
    case 'Site': return DashboardPaths.SiteDashboard;
    case 'Company': return DashboardPaths.CompanyDashboard;
    default: throw new Error(`Unknown org level ${l}`);
  }
}
export function useGetLinkByOrgLevel() {
  const appType = useApplicationType();
  if(appType === 'Portal') {
    return getPortalLinkByOrgLevel;
  }
  return getLinkByOrgLevel;
}
export function getLinkByOrgLevel(id: string, name: string, l: OrgLevel) {
  const base = getBaseLinkByOrgLevel(l);
  return makeUrl(base, {id: id, name: name});
}

export function getPortalLinkByOrgLevel(id: string, name: string, l: OrgLevel) {
  const link = getPortalUrlBasePathByType(l);
  return makeUrl(link, {id: id, name: name});
}


const applyAssetPath = (a: SimpleUserViewAsset) => makeUrl(AssetDetails, {name: a.name, id: a.uuid});
export const useApplyAssetPath = () => {
  const type = useApplicationType();
  if(type === 'Portal') {
    return (a: SimpleUserViewAsset) => `/?p=AssetPage&pid=${a.uuid}`;
  }
  return applyAssetPath;
};
export function getCompanyConfBtn(breadcrumbs: BreadCrumbData[] | undefined): IconButtonProps {
  const companyId = breadcrumbs?.find(b => b.type === 'Company')?.intId;
  return {
    icon: 'cog',
    newTab: true,
    tooltip: 'Company Configuration',
    to: RoutePaths.companyConfiguration.replace(':companyId', String(companyId))
  };
}
