import { useRmxServiceApi } from '../api';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { useModal } from 'src/portal/hooks/useModal';
import { RmxServiceSampleModal, type RmxServiceSampleModalParam } from '../RmxServiceSampleModal/RmxServiceSampleModal';
import React, { useMemo, useState } from 'react';
import { sampleColumnsDef } from '../RmxServiceTableDefinitions/SamplesTableDefinition';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import { DayFilter } from '../../utils/DayFilter/DayFilter';

function useSamplesTable(filterByAssetId?: number) {
  const api = useRmxServiceApi();
  const sampleModal = useModal<RmxServiceSampleModalParam>();
  const [sampleDateFilter, setSampleDateFilter] = useState<number | null | string>(null);
  const sampleColumns = useMemo(() => sampleColumnsDef?.(), []);

  const samplesTable = useServerDataTable({
    queryKey: ['samples', sampleDateFilter],
    columns: sampleColumns,
    initialSorting: [{ id: 'sampleDate', desc: false }],
    getRows: async (params) => {
      return await api.getSamples({ ...params, assetId: filterByAssetId ?? null, daysFilter: sampleDateFilter });
    }
    // initialSorting: [{ id: 'Recommendations', desc: false }]
  });
  return { samplesTable, sampleModal, sampleDateFilter, setSampleDateFilter };
}

export function SamplesTable() {
  const { samplesTable, sampleModal, sampleDateFilter, setSampleDateFilter } = useSamplesTable();
  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={samplesTable}
        onRowClick={async (row) => await sampleModal.open({ resource: row })}
        footer={<DayFilter value={sampleDateFilter} setGlobalDateFilter={setSampleDateFilter} />}
      />
      {sampleModal.isOpen && <RmxServiceSampleModal state={sampleModal} />}
    </>
  );
}
