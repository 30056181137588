import { type ChangeEvent, useMemo, useState } from 'react';
import { format, addMinutes, startOfDay, parse, setHours, setMinutes } from 'date-fns';
import { Form } from 'react-bootstrap';

interface Props {
  defaultDate: Date;
  increment: number;
  disabled?: boolean;
  hoursToGenerate?: number; // Number of hours to generate time options for starting from the default date
  onChange: (value: Date) => void;
}

export function TimeDropDown({ disabled, defaultDate, increment, onChange, hoursToGenerate }: Props) {
  const [selectedTime, setSelectedTime] = useState(defaultDate);

  // Handle select change
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newTime = event.target.value;
    setSelectedTime(parse(newTime, 'hh:mm a', new Date()));
    onChange(parse(newTime, 'hh:mm a', new Date()));
  };

  // Memoize time options to avoid unnecessary recalculations on re-renders
  const timeOptions = useMemo(() => generateTimeOptions(startOfDay(defaultDate), increment, hoursToGenerate), [defaultDate, hoursToGenerate, increment]);

  return (
    <Form.Group>
      <Form.Select
        value={format(selectedTime, 'hh:mm a')}
        style={{ font: '12px', borderRadius: '4px', background: '#7B7B7B', color: 'white' }}
        disabled={disabled}
        onChange={handleSelectChange}
      >
        {timeOptions.map((time, index) => {
          return (
            <option key={index} value={time}>
              {time}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

function roundToNearestIncrement(date: Date, increment: number): Date {
  const roundedMinutes = Math.round(date.getMinutes() / increment) * increment;
  return setMinutes(setHours(date, date.getHours()), roundedMinutes);
}

// Generate time options starting from the default date and incrementing by the specified increment
// the hoursToGenerate parameter specifies the number of hours to generate time options for starting from the default date
function generateTimeOptions(defaultDate: Date, increment: number, hoursToGenerate = 24): string[] {
  const start = defaultDate ? roundToNearestIncrement(defaultDate, increment) : startOfDay(new Date());
  const times: string[] = [];
  for (let minutes = 0; minutes <= hoursToGenerate * 60; minutes += increment) {
    const time = addMinutes(start, minutes);
    times.push(format(time, 'hh:mm a'));
  }
  return times;
}
