import { createStandardQueryClient } from '../../../../portal/utils/react-query/client';
import ReactDOM from 'react-dom/client';
import React, { useMemo, useState } from 'react';
import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import { useLocalStorage } from 'usehooks-ts';
import { getInstallationDataBySiteUuid, hiddenFilter } from '../../../../api/userDashboardApi';
import { firstOrUndefined } from '../../../../common/util';
import { ReactQueryLayout } from '../../../../components/util/LoadableResourceLayout';
import { Tab, Tabs } from '../../../../components/util/Controls';
import { SystemProcessTab } from './components/SystemProcessTab/SystemProcessTab';
import { AssetsTab } from './components/AssetsTab/AssetsTab';
import { ApplicationTypeProvider } from '../../../../ApplicationTypeContext';
import style from './portal-styling.module.scss';
import { AlertTable } from './components/AlertTable/AlertTable';
import { convertAlertStatusSymbolToStatusIndicatorSymbol, StatusIndicator } from '../../../../portal/status-indicator/StatusIndicator/StatusIndicator';
import { PanelLayout } from '../../../../components/util/PanelLayout/PanelLayout';
import { useGetPreviousBreadCrumbLink } from '../../DashboardBreadCrumbs/DashboardBreadCrumbs';
import type { InstallationOpenMenuModal } from '../../../../portal/menu-modal/MenuModal/portal-asset-context';

declare global {
  interface Window {
    initInstallationPanel: (target: string) => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShowActionModal: (assetId: number|string, notes?: string, isMonitored?: boolean) => void;
  }
}
const queryClient = createStandardQueryClient();
export function initInstallationPanel(target: string) {
  hiddenFilter.ShowHidden = false;
  const root = ReactDOM.createRoot(document.getElementById(target) as HTMLElement);
  root.render(
    <React.StrictMode>
      <ApplicationTypeProvider value="Portal">
      <QueryClientProvider client={queryClient}>
        <InstallationPanelWrapper />
      </QueryClientProvider>
      </ApplicationTypeProvider>
    </React.StrictMode>
  );
}



export function InstallationPanelWrapper() {
  const siteUuid = useMemo(() => new URLSearchParams(window.location.search).get('pid')!, []);
  const [savedActiveTab, saveTabId] = useLocalStorage(`installation-${siteUuid}-active-system-process-tab`, null as string|null);
  const queryResult = useQuery({
    queryKey: ['installation-data', siteUuid],
    queryFn: async () => await getInstallationDataBySiteUuid(siteUuid),
    refetchInterval: 60 * 1000
  }), { data: installationData, refetch } = queryResult;
  const firstSpId = firstOrUndefined(installationData?.systemProcesses?.map(sp => sp.id));
  const defaultActiveKey = firstSpId ? String(firstSpId) : 'assets';
  const [activeKeyInner, setActiveKey] = useState<string | null>(savedActiveTab);
  const possibleKeys = queryResult?.data?.systemProcesses?.map(sp => String(sp.id))?.concat(['assets']);
  const isValidKey = possibleKeys?.includes(activeKeyInner ?? "") ?? true;
  const activeKey = (isValidKey ? activeKeyInner : null) ?? defaultActiveKey;
  const getPreviousBreadCrumbLink = useGetPreviousBreadCrumbLink();
  return (
    <PanelLayout
      headerLeft={installationData && <StatusIndicator
        className={'ml-2 ms-2'}
        color={installationData.alertStatus.color}
        label={installationData.siteName}
        requestIndicatorNumber={0}
        symbol={convertAlertStatusSymbolToStatusIndicatorSymbol(installationData.alertStatus.symbol)}
        onClick={() => (window.OpenIndicatorMenuModal as InstallationOpenMenuModal)(installationData.siteUuid, 'Site', !!installationData.installation)}
      />}
      breadCrumbs={installationData?.breadCrumbs}
      darker={true}
      backLink={getPreviousBreadCrumbLink(installationData?.breadCrumbs)}
      buttons={[]}>
    <div className={style['portal-installation-container']}>
      <ReactQueryLayout state={queryResult}>
        {installationData && <>
          <AlertTable alerts={installationData.alerts} type='Installation' />
          <Tabs
            className={style['installation-tabs']}
            id="installation-tabs"
            activeKey={activeKey}
            onSelect={newKey => {
              saveTabId(newKey);
              setActiveKey(newKey);
            }}
          >
            {installationData.systemProcesses.map(sp => (
              <Tab eventKey={String(sp.id)} title={sp.name} key={sp.id}>
                <SystemProcessTab active={activeKey === String(sp.id)} systemProcessUuid={sp.uuid} refetch={refetch} />
              </Tab>
            ))}
            <Tab eventKey="assets" title="Assets" key={`site-${installationData.siteUuid}/assets`}>
              <AssetsTab reload={refetch} installationData={installationData} />
            </Tab>
          </Tabs>
        </>
        }
      </ReactQueryLayout>
    </div>
    </PanelLayout>
  );
}

window.initInstallationPanel = initInstallationPanel;
