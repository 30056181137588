import ReactDOM from 'react-dom/client';
import React from 'react';
import { LazyFormRenderer } from './LazyFormRenderer';
import { QueryClientProvider } from '@tanstack/react-query';
import { createStandardQueryClient } from '../../utils/react-query/client';
import { GlobalConfirmDialogProvider } from 'src/portal/utils/GlobalConfirmDialog';
import { RmxServiceApiProvider } from '../../rmx-service/api';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';

const queryClient = createStandardQueryClient();

// @ts-ignore
window.BootstrapFormRenderer = (targetId: string) => {
  const root = ReactDOM.createRoot(document.getElementById(targetId) as HTMLElement);
  const portalApi = getPortalServiceApi();

  root.render(
    <React.StrictMode>
      <GlobalConfirmDialogProvider>
        <RmxServiceApiProvider value={portalApi}>
          <QueryClientProvider client={queryClient}>
            <LazyFormRenderer />
          </QueryClientProvider>
        </RmxServiceApiProvider>
      </GlobalConfirmDialogProvider>
    </React.StrictMode>
  );
};
