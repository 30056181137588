import { useApplicationType } from '../ApplicationTypeContext';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export function useNavigator() {
  const appType = useApplicationType();
  if(appType === 'Support') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    return navigate;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback((to: string) => {
    window.location.href = to;
  }, []);
}
