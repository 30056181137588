import { Card } from 'react-bootstrap';
import React, { type ReactElement, useEffect } from 'react';
import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { useMutation, type UseQueryResult } from '@tanstack/react-query';
import { formatTime } from '../../../util/formatters';
import { formatDateToTimeString } from '../../utils/date-time';
import { Spinner } from '../../shared-compat/DataTable/DataTable';
import type { Activity, ActivityTime } from '../Service.types';
import { RmxServiceTimePicker } from './RmxServiceTimePicker';
import { parse } from 'date-fns';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { useRmxServiceApi } from '../api';

export type TimeInfo = {
  date: string;
  startTime: string;
  endTime: string;
  total: string;
  type: string;
  actions?: ReactElement;
};

interface Props {
  activity: Activity;
  timeTableData: TimeInfo[];
  setTimeTableData: React.Dispatch<React.SetStateAction<TimeInfo[]>>;
  timeHistoryQuery: UseQueryResult<ActivityTime[], Error>;
  enabled: boolean;
}

export function RmxServiceTime({ enabled, activity, timeTableData, setTimeTableData, timeHistoryQuery }: Props) {
  const { data: activityTimeRows, refetch: refetchActivityTimeRows, isRefetching: refetchingTimeHistory, isFetching: fetchingTimeHistory } = timeHistoryQuery;

  const api = useRmxServiceApi();
  const { mutateAsync: deleteActivityTime, isPending: pendingDeleteActivityTime } = useMutation({
    mutationFn: async ({ entryStartTime, entryDate }: { entryStartTime: Date; entryDate: Date }) => {
      await api.deleteActivityTime(activity!.id, entryStartTime, entryDate);
      await refetchActivityTimeRows();
    }
  });

  const timeTableColumns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Start Time', accessorKey: 'startTime' },
    { header: 'End Time', accessorKey: 'endTime' },
    { header: 'Total', accessorKey: 'total' },
    { header: 'Type', accessorKey: 'type', valueFormatter: (value) => <p style={{ minWidth: '5rem' }}>{value}</p> },
    {
      header: '',
      accessorKey: 'actions',
      valueFormatter: (value, row) => {
        const rowTimeInfo = row as TimeInfo;
        if (!rowTimeInfo.actions) {
          const formatStringDate = 'M/d/yyyy';
          const entryDate = parse(rowTimeInfo.date as string, formatStringDate, new Date());
          const formatStringTime = 'hh:mm aa';
          const startTimeEntry = parse(rowTimeInfo.startTime as string, formatStringTime, new Date());

          return (
            <LoadingButton
              size='sm'
              variant='secondary'
              disabled={!enabled}
              loading={pendingDeleteActivityTime}
              onClick={async () => {
                await deleteActivityTime({ entryStartTime: startTimeEntry, entryDate: entryDate });
              }}
              label='Delete'
            />
          );
        } else {
          return rowTimeInfo.actions;
        }
      }
    }
  ] satisfies SimpleColumnDef[];

  useEffect(() => {
    if (activityTimeRows !== undefined) {
      setTimeTableData(
        activityTimeRows.map((activityTime) => ({
          date: activityTime.entryDate.toLocaleDateString(),
          startTime: formatDateToTimeString(activityTime.startTime, true),
          endTime: formatDateToTimeString(activityTime.endTime, true),
          total: formatTime(activityTime.totalTime.getHours(), activityTime.totalTime.getMinutes()),
          type: activityTime.type
        }))
      );
    }
  }, [activityTimeRows, setTimeTableData]);

  return (
    <div style={{ minHeight: '415px' }}>
      <RmxServiceTimePicker
        activity={activity}
        timeTableData={timeTableData}
        options={['Select', 'Onsite', 'Offsite', 'Travel', 'Lunch']}
        refetchActivityTimeRows={refetchActivityTimeRows}
      />
      <Card className='my-4'>
        <Card.Header>Time History</Card.Header>
        <Card.Body style={{ overflowY: 'auto', maxHeight: '255px' }}>
          {fetchingTimeHistory || refetchingTimeHistory ? (
            <Spinner />
          ) : timeTableData.length !== 0 ? (
            <ReactTable tableClassName={'time-table'} columns={timeTableColumns} includeHeaders={true} striped={true} bordered={false} data={timeTableData} />
          ) : (
            <> No Time Entries Have Been Added </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
